import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'

class NotFoundPage extends React.Component {
  render() {
    const { location, language, disableMenu } = this.props
    return (
			<>
				<Helmet title={`Page not found`} />
        <h1>Page not found</h1>
			</>
    )
  }
}

export default NotFoundPage
